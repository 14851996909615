const PROPERTY = "property";
const CONTENT = "content";

const OG_URL = "og:url";
const OG_TYPE = "og:type";
const OG_TITLE = "og:title";
const OG_DESCRIPTION = "og:description";
const OG_IMAGE = "og:image";

export function install(Vue) {
  Vue.prototype.$setOpenGraphTags = function (event) {
    document.title = event.title;
    document.querySelector('meta[name="description"]').setAttribute('content', event.description);


    appendOgTag(OG_URL, `${window.location.origin}/${event.lp_url}`);
    appendOgTag(OG_TYPE, "website");
    appendOgTag(OG_TITLE, event.title);
    appendOgTag(OG_DESCRIPTION, event.description);
    appendOgTag(OG_IMAGE, event.lp_banner && event.lp_banner.file_url && event.lp_banner.file_url.split("?")[0]);
  };
}

function appendOgTag(property, content) {
  const ogTagElement = document.createElement("meta");
  ogTagElement.setAttribute(PROPERTY, property);
  ogTagElement.setAttribute(CONTENT, content);
  document.head.appendChild(ogTagElement);
}

export default {
  install,
};
