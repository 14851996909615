import Constants from "@/web/constants";

let routes = [
  // {
  //   path: "/",
  //   name: Constants.ROUTE_DASHBOARD,
  //   component: () => import(/* webpackChunkName: 'invalid' */ "@/web/views/accessible/Dashboard"),
  // },
  {
    path: "/reset-password",
    name: Constants.ROUTE_RESET_PASSWORD_EVENTLESS,
    component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ResetPassword"),
  },
  {
    path: "/forgot-password",
    name: Constants.ROUTE_FORGOT_PASSWORD_EVENTLESS,
    component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ForgotPassword"),
  },
  {
    path: "/registration/completed",
    name: Constants.ROUTE_EMAIL_CONFIRMATION_SUCCESS,
    component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationSuccess"),
  },
  {
    path: "/registration/invalid-token",
    name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_INVALID,
    component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
  },
  {
    path: "/registration/token-expired",
    name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_EXPIRED,
    component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
  },
  {
    path: "/:slug",
    component: () => import(/* webpackChunkName: 'event' */ "@/web/views/Event"),
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: 'event' */ "@/web/views/Main"),
        children: [
          {
            path: "",
            name: Constants.ROUTE_MAIN_INTRODUCTION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Introduction"),
          },
          {
            path: "component/:componentId/survey_quiz_component",
            name: Constants.ROUTE_QUIZZES,
            component: () => import(/* webpackChunkName: 'quizzes' */ "@/web/views/accessible/Quizzes"),
          },
          {
            path: "component/:componentId/survey_quiz_component/:quizId",
            name: Constants.ROUTE_QUIZ_QUESTIONS,
            component: () => import(/* webpackChunkName: 'quizzes' */ "@/web/views/restricted/Quiz"),
          },
          {
            path: "component/:componentId/exhibitor/:exhibitorId",
            name: Constants.ROUTE_EXHIBITOR,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Exhibitor"),
          },
          {
            path: "component/:componentId/attendees",
            name: Constants.ROUTE_ATTENDEES,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Attendees"),
          },
          {
            path: "component/:componentId/agenda_sessions/:agendaSessionId",
            name: Constants.ROUTE_AGENDA_SESSION_ATTENDEES,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Attendees"),
          },
          {
            path: "buy-ticket/:ticketId",
            name: Constants.ROUTE_BUY_TICKET,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: route => ({
              ticketId: (route.params.ticketId && Number.parseInt(route.params.ticketId)) || route.params.ticketId,
            }),
          },
          {
            path: "buy-ticket",
            name: Constants.ROUTE_BUY_TICKET_NO_SELECTION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
          },
          {
            path: "buy-ticket/confirmation",
            name: Constants.ROUTE_BUY_TICKET_CONFIRMATION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: { confirmation: true },
          },
          {
            path: "buy-ticket/:ticketId/confirmation",
            name: Constants.ROUTE_BUY_TICKET_CONFIRMATION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: { ticketId: route => route.params.ticketId, confirmation: true },
          },
          {
            path: "profile",
            name: Constants.ROUTE_MY_PROFILE,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/MyProfile"),
          },
          {
            path: "profile/edit",
            name: Constants.ROUTE_EDIT_PROFILE,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditProfile"),
          },
          //Feature disabled
          // {
          //   path: "profile/edit-email",
          //   name: Constants.ROUTE_EDIT_EMAIL,
          //   component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditEmail"),
          // },
          {
            path: "profile/edit-password",
            name: Constants.ROUTE_EDIT_PASSWORD,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditPassword"),
          },
          {
            path: "profile/availability",
            name: Constants.ROUTE_AVAILABILITY,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/Availability"),
          },
          {
            path: "inbox",
            name: Constants.ROUTE_INBOX,
            component: () => import(/* webpackChunkName: 'inbox' */ "@/web/views/restricted/Inbox"),
            props: { threadId: route => route.params.threadId },
            children: [
              {
                path: "create/user/:userId?",
                name: Constants.ROUTE_INBOX_CREATE_THREAD,
                component: () => import(/* webpackChunkName: 'inbox' */ "@/web/components/inbox/InboxCreateThread"),
                props: true,
              },
              {
                path: "thread/:threadId",
                name: Constants.ROUTE_INBOX_THREAD,
                component: () => import(/* webpackChunkName: 'inbox' */ "@/web/components/inbox/InboxThread"),
              },
            ],
          },
          {
            path: "friends",
            name: Constants.ROUTE_FRIENDS,
            component: () => import(/* webpackChunkName: 'friends' */ "@/web/views/restricted/Friends"),
            children: [
              {
                path: "invitations",
                name: Constants.ROUTE_FRIENDS_INVITATIONS,
                component: () => import(/* webpackChunkName: 'friends' */ "@/web/views/restricted/Friends"),
              },
            ],
          },
          {
            path: "manage",
            name: Constants.ROUTE_MODERATOR_MAIN,
            component: () => import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/ModeratorMain"),
            children: [
              {
                path: "exhibitor/:exhibitorId",
                name: Constants.ROUTE_MODERATOR_EXHIBITOR,
                component: () =>
                  import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitor"),
                props: route => ({
                  exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                }),
                children: [
                  {
                    path: "create",
                    name: Constants.ROUTE_MODERATOR_EXHIBITOR_CREATE_UPDATE,
                    component: () =>
                      import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitorUpdate"),
                    props: route => ({
                      exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                    }),
                  },
                  {
                    path: "update/:exhibitorUpdateId",
                    name: Constants.ROUTE_MODERATOR_EXHIBITOR_UPDATE,
                    component: () =>
                      import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitorUpdate"),
                    props: route => ({
                      exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                      exhibitorUpdateId: route.params.exhibitorUpdateId && Number.parseInt(route.params.exhibitorUpdateId),
                    }),
                  },
                ],
              },
              {
                path: "speaker/:speakerId",
                name: Constants.ROUTE_MODERATOR_SPEAKER,
                component: () => import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/speaker/ModeratorSpeaker"),
                props: route => ({
                  speakerId: route.params.speakerId && Number.parseInt(route.params.speakerId),
                }),
              },
            ],
          },
          {
            path: "business-matching",
            name: Constants.ROUTE_BUSINESS_MATCHING,
            component: () => import(/* webpackChunkName: 'business_matching' */ "@/web/views/restricted/BusinessMatching"),
            props: { meetingId: route => route.params.meetingId },
            children: [
              {
                path: "accepted/:meetingId?",
                name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED,
                component: () =>
                  import(/* webpackChunkName: 'business_matching' */ "@/web/components/businessmatching/meeting/BMAcceptedMeetings"),
                props: true,
              },
              {
                path: "pending/:meetingId?",
                name: Constants.ROUTE_BUSINESS_MATCHING_PENDING,
                component: () =>
                  import(/* webpackChunkName: 'business_matching' */ "@/web/components/businessmatching/meeting/BMPendingMeetings"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "registration/completed",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_SUCCESS,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationSuccess"),
      },
      {
        path: "registration/invalid-token",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_INVALID,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
      },
      {
        path: "registration/token-expired",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_EXPIRED,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
      },
      {
        path: "access-code",
        name: Constants.ROUTE_ACCESS_CODE,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/AccessCode"),
      },
      {
        path: "login",
        name: Constants.ROUTE_LOGIN,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/Login"),
      },
      {
        path: "register",
        name: Constants.ROUTE_REGISTER,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/Register"),
      },
      {
        path: "forgot-password",
        name: Constants.ROUTE_FORGOT_PASSWORD,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ForgotPassword"),
      },
      {
        path: "reset-password",
        name: Constants.ROUTE_RESET_PASSWORD,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ResetPassword"),
      },
      {
        path: "legal",
        name: Constants.ROUTE_LEGAL,
        component: () => import(/* webpackChunkName: 'licenses' */ "@/web/views/accessible/Licenses"),
      },
      {
        path: "agenda/:componentId/session/:sessionId/discussion",
        name: Constants.ROUTE_SESSION_DISCUSSION,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/AgendaSessionDiscussion"),
      },
      {
        path: "leaderboard/:quizUuid",
        name: Constants.ROUTE_QUIZ_LEADERBOARD,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/QuizLeaderboard"),
      },
      {
        path: "survey-results/:surveyUuid",
        name: Constants.ROUTE_SURVEY_RESULTS,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/SurveyResults"),
      },
      {
        path: "treasure-hunt/:treasureUuid",
        name: Constants.ROUTE_TREASURE_HUNT_LEADERBOARD,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/TreasureLeaderboard"),
      },
    ],
  },

  {
    path: "/",
    component: () => import(/* webpackChunkName: 'event' */ "@/web/views/Event"),
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: 'event' */ "@/web/views/Main"),
        children: [
          {
            path: "",
            name: Constants.ROUTE_MAIN_INTRODUCTION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Introduction"),
          },
          {
            path: "component/:componentId/survey_quiz_component",
            name: Constants.ROUTE_QUIZZES,
            component: () => import(/* webpackChunkName: 'quizzes' */ "@/web/views/accessible/Quizzes"),
          },
          {
            path: "component/:componentId/survey_quiz_component/:quizId",
            name: Constants.ROUTE_QUIZ_QUESTIONS,
            component: () => import(/* webpackChunkName: 'quizzes' */ "@/web/views/restricted/Quiz"),
          },
          {
            path: "component/:componentId/exhibitor/:exhibitorId",
            name: Constants.ROUTE_EXHIBITOR,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Exhibitor"),
          },
          {
            path: "component/:componentId/attendees",
            name: Constants.ROUTE_ATTENDEES,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Attendees"),
          },
          {
            path: "component/:componentId/agenda_sessions/:agendaSessionId",
            name: Constants.ROUTE_AGENDA_SESSION_ATTENDEES,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/Attendees"),
          },
          {
            path: "buy-ticket/:ticketId",
            name: Constants.ROUTE_BUY_TICKET,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: route => ({
              ticketId: (route.params.ticketId && Number.parseInt(route.params.ticketId)) || route.params.ticketId,
            }),
          },
          {
            path: "buy-ticket",
            name: Constants.ROUTE_BUY_TICKET_NO_SELECTION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
          },
          {
            path: "buy-ticket/confirmation",
            name: Constants.ROUTE_BUY_TICKET_CONFIRMATION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: { confirmation: true },
          },
          {
            path: "buy-ticket/:ticketId/confirmation",
            name: Constants.ROUTE_BUY_TICKET_CONFIRMATION,
            component: () => import(/* webpackChunkName: 'restricted' */ "@/web/views/restricted/BuyTicket"),
            props: { ticketId: route => route.params.ticketId, confirmation: true },
          },
          {
            path: "profile",
            name: Constants.ROUTE_MY_PROFILE,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/MyProfile"),
          },
          {
            path: "profile/edit",
            name: Constants.ROUTE_EDIT_PROFILE,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditProfile"),
          },
          //Feature disabled
          // {
          //   path: "profile/edit-email",
          //   name: Constants.ROUTE_EDIT_EMAIL,
          //   component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditEmail"),
          // },
          {
            path: "profile/edit-password",
            name: Constants.ROUTE_EDIT_PASSWORD,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/EditPassword"),
          },
          {
            path: "profile/availability",
            name: Constants.ROUTE_AVAILABILITY,
            component: () => import(/* webpackChunkName: 'profile' */ "@/web/views/restricted/user/Availability"),
          },
          {
            path: "inbox",
            name: Constants.ROUTE_INBOX,
            component: () => import(/* webpackChunkName: 'inbox' */ "@/web/views/restricted/Inbox"),
            props: { threadId: route => route.params.threadId },
            children: [
              {
                path: "create/user/:userId?",
                name: Constants.ROUTE_INBOX_CREATE_THREAD,
                component: () => import(/* webpackChunkName: 'inbox' */ "@/web/components/inbox/InboxCreateThread"),
                props: true,
              },
              {
                path: "thread/:threadId",
                name: Constants.ROUTE_INBOX_THREAD,
                component: () => import(/* webpackChunkName: 'inbox' */ "@/web/components/inbox/InboxThread"),
              },
            ],
          },
          {
            path: "friends",
            name: Constants.ROUTE_FRIENDS,
            component: () => import(/* webpackChunkName: 'friends' */ "@/web/views/restricted/Friends"),
            children: [
              {
                path: "invitations",
                name: Constants.ROUTE_FRIENDS_INVITATIONS,
                component: () => import(/* webpackChunkName: 'friends' */ "@/web/views/restricted/Friends"),
              },
            ],
          },
          {
            path: "manage",
            name: Constants.ROUTE_MODERATOR_MAIN,
            component: () => import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/ModeratorMain"),
            children: [
              {
                path: "exhibitor/:exhibitorId",
                name: Constants.ROUTE_MODERATOR_EXHIBITOR,
                component: () =>
                  import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitor"),
                props: route => ({
                  exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                }),
                children: [
                  {
                    path: "create",
                    name: Constants.ROUTE_MODERATOR_EXHIBITOR_CREATE_UPDATE,
                    component: () =>
                      import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitorUpdate"),
                    props: route => ({
                      exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                    }),
                  },
                  {
                    path: "update/:exhibitorUpdateId",
                    name: Constants.ROUTE_MODERATOR_EXHIBITOR_UPDATE,
                    component: () =>
                      import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/exhibitor/ModeratorExhibitorUpdate"),
                    props: route => ({
                      exhibitorId: route.params.exhibitorId && Number.parseInt(route.params.exhibitorId),
                      exhibitorUpdateId: route.params.exhibitorUpdateId && Number.parseInt(route.params.exhibitorUpdateId),
                    }),
                  },
                ],
              },
              {
                path: "speaker/:speakerId",
                name: Constants.ROUTE_MODERATOR_SPEAKER,
                component: () => import(/* webpackChunkName: 'moderator' */ "@/web/views/restricted/moderator/speaker/ModeratorSpeaker"),
                props: route => ({
                  speakerId: route.params.speakerId && Number.parseInt(route.params.speakerId),
                }),
              },
            ],
          },
          {
            path: "business-matching",
            name: Constants.ROUTE_BUSINESS_MATCHING,
            component: () => import(/* webpackChunkName: 'business_matching' */ "@/web/views/restricted/BusinessMatching"),
            props: { meetingId: route => route.params.meetingId },
            children: [
              {
                path: "accepted/:meetingId?",
                name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED,
                component: () =>
                  import(/* webpackChunkName: 'business_matching' */ "@/web/components/businessmatching/meeting/BMAcceptedMeetings"),
                props: true,
              },
              {
                path: "pending/:meetingId?",
                name: Constants.ROUTE_BUSINESS_MATCHING_PENDING,
                component: () =>
                  import(/* webpackChunkName: 'business_matching' */ "@/web/components/businessmatching/meeting/BMPendingMeetings"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "registration/completed",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_SUCCESS,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationSuccess"),
      },
      {
        path: "registration/invalid-token",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_INVALID,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
      },
      {
        path: "registration/token-expired",
        name: Constants.ROUTE_EMAIL_CONFIRMATION_TOKEN_EXPIRED,
        component: () => import(/* webpackChunkName: 'email-confirmation' */ "@/web/views/access/EmailConfirmationTokenExpired"),
      },
      {
        path: "access-code",
        name: Constants.ROUTE_ACCESS_CODE,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/AccessCode"),
      },
      {
        path: "login",
        name: Constants.ROUTE_LOGIN,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/Login"),
      },
      {
        path: "register",
        name: Constants.ROUTE_REGISTER,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/Register"),
      },
      {
        path: "forgot-password",
        name: Constants.ROUTE_FORGOT_PASSWORD,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ForgotPassword"),
      },
      {
        path: "reset-password",
        name: Constants.ROUTE_RESET_PASSWORD,
        component: () => import(/* webpackChunkName: 'access' */ "@/web/views/access/ResetPassword"),
      },
      {
        path: "legal",
        name: Constants.ROUTE_LEGAL,
        component: () => import(/* webpackChunkName: 'licenses' */ "@/web/views/accessible/Licenses"),
      },
      {
        path: "agenda/:componentId/session/:sessionId/discussion",
        name: Constants.ROUTE_SESSION_DISCUSSION,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/AgendaSessionDiscussion"),
      },
      {
        path: "leaderboard/:quizUuid",
        name: Constants.ROUTE_QUIZ_LEADERBOARD,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/QuizLeaderboard"),
      },
      {
        path: "survey-results/:surveyUuid",
        name: Constants.ROUTE_SURVEY_RESULTS,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/SurveyResults"),
      },
      {
        path: "treasure-hunt/:treasureUuid",
        name: Constants.ROUTE_TREASURE_HUNT_LEADERBOARD,
        component: () => import(/* webpackChunkName: 'external' */ "@/web/views/external/TreasureLeaderboard"),
      },
    ],
  },

];

if (process.env.NODE_ENV === "development") {
  routes[routes.length - 1].children = routes[routes.length - 1].children.concat([
    {
      path: "localization-parser",
      name: "localization-parser",
      component: () => import(/* webpackChunkName: 'localization-parser' */ "@/web/views/dev/LocalizationParser"),
    },
  ]);
  routes[routes.length - 1].children = routes[routes.length - 1].children.concat([
    {
      path: "discount-codes",
      name: "discount-codes",
      component: () => import(/* webpackChunkName: 'create-discount-code' */ "@/web/views/dev/discountcodes/DiscountCodes"),
    },
  ]);
  routes[routes.length - 1].children = routes[routes.length - 1].children.concat([
    {
      path: "custom-form-builder",
      name: "custom-form-builder",
      component: () => import(/* webpackChunkName: 'custom-form-builder' */ "@/web/views/dev/formbuilder/CustomFormBuilder"),
    },
  ]);
  routes[routes.length - 1].children = routes[routes.length - 1].children.concat([
    {
      path: "invoice-generator",
      name: "invoice-generator",
      component: () => import(/* webpackChunkName: invoice-generator */ "@/web/views/dev/invoicegenerator/InvoiceGenerator"),
    },
  ]);
}

export default routes;
